import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import stats from "../img/providenceHeight.PNG"


const ProvidenceHeightsAbout = () => {
    React.useEffect(()=>{
        window.scrollTo(0, 0); 
    })
    return (
        <>
            <Navbar></Navbar>
            <section className="breadcrumb " style={{backgroundImage: 'url(img/gallery/DSC_1462.JPG)', backgroundPosition: 'top', backgroundSize: 'cover',}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb_iner text-center">
                                <div className="breadcrumb_iner_item">
                                    <h2>About Us</h2>
                                    <p>Home<span>/</span>About Us</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className=' d-flex'  >
                <a href='/providenceHeights/about' className='py-4  text-white w-50' style={{backgroundColor: 'rgb(76, 175, 79)'}}>PROVIDENCE HEIGHTS ‘PH’ SECONDARY SCHOOL</a>
                <a href='/about'  className='py-4 w-50' style={{backgroundColor: '#f5f5f5',color: 'rgb(76, 175, 79)'}}>PROVIDENCE SCHOOLS</a>
            </div>

            <section className="feature_part single_feature_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-xl-3">
                            <div className="single_feature_text mt-5">
                                <h2>PROVIDENCE HEIGHTS ‘PH’   <br /> SECONDARY SCHOOL</h2>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-9">
                            <p className="mb-30 text-left">Providence Heights ‘Ph’ Secondary School was born out of the conception of the founding fathers to give qualitative education to the less privileged pupils who could not afford the opportunity of attaining sound education in highbrow fee paying schools. The conception was midwifed on Monday 26, September, 1994 at Fagba, Iju-Agege, Lagos, South West, Nigeria.</p>
                            <p className="mb-30 text-left">At the commencement, the school enrolled 59 pupils for both JSS1 and JSS2 – 36 males and 23 females. The infrastructure in the school then comprised four classrooms, a small administrative office, a security house. The school started with ten personnel – eight teaching staff, one security officer and a clerk. By November 1994, the population of the students and the staff had risen to eighty and nineteen respectively.</p>
                            <p className="mb-30 text-left">By 2014 when the school was 20 years, by sheer dint of unflinching hard work and His Grace, the population had witnessed a meteoric rise to about eight hundred students and 64 professionally and academically qualified teaching staff and 28 non-teaching staff. </p>
                            <p className="mb-30 text-left">Structurally, the school is like an oak that grows from a very small nut into a giant tree. The school has been able to put up beautiful edifices for conducive learning. Presently, the school consists of twenty-four spacious and well ventilated classrooms (air conditioned) of four per level (JSS1 – SS3); well-equipped laboratories for science related subjects – Physics, Chemistry, Biology, Home Economics and a Basic Technology workshop; well-equipped studios for vocational related subjects – Music, Creative Arts, Short-hand and Typing; a large modern well equipped functioning ICT suite. Added to the list are the well-furnished, home away from home, two separate hostels for boys and girls.</p>
                            <p className="mb-30 text-left">Academically, the school operates basically the Federal Curriculum and Policy on education. This has paved way for the school to compartmentalize the subjects into three, viz: Science, Arts and Commercial. The academic performance of the school has been a story of success galore. Since its approval in 1998 as an examination centre for the SSCE, it has been a story of academic excellence.  </p>
                            <p className="mb-30 text-left">Considering the high level of moral decadence amongst today’s youths, Providence Heights ‘Ph’ Secondary School has a unique way of moulding its students to very responsible and enviable future ambassadors of ethics, moral, etiquette and diligence. Over the years, the school has relentlessly imparted into the lives of its students the philosophy of earning their successes in life in a desirable manner, and whichever way they perceive life, hardwork surely pays off. The result of this is seen in the life of ex-students far and wide. One of such received a Presidential Handshake for academic excellence at Howard University, U.S.A. Another, Master Adegoke Fagbemi (2004 set), now a resident doctor of general and abdominal surgery in Germany received tne Best Medical Student Award (2011) at University of Orebea, Romania. It suffices to say that many are resident doctors across the globe – Nigeria, the United State of America, Great Britain, Canada, e.t.c. There are some who are pilots, while there are many others occupying middle and senior management/executive positions in private and public organisations in and outside Nigeria. Also, some of these ex-students are in the academia. Lots of our ex-students have enjoyed various scholarships in the pursuit of their tertiary education abroad – U.S.A, Canada, U.K and European Union countries. All this is a direct attestation to the qualitative education received at PROVIDENCE HEIGHTS ‘Ph’ SECONDARY SCHOOL.</p>
                            <p className="mb-30 text-left">As part of its tenets, Providence Heights ‘Ph’ Secondary School strongly believes that it is more honourable to fail than to cheat in examinations. Consequent upon this, the Lagos State Ministry of Education via Education District 1, Agege recognized this laudable virtue about the school and gave the school a Merit Award as MALPRACTICE FREE SCHOOL for year 2005/2006.</p>
                            <p className="mb-30 text-left">It is of note to say that due priority is equally apportioned to sports. Inter-House Sports Fiesta is organized biennially. The last one was celebrated in a grand style with all the pomp and circumstance at Agege Stadium on Thursday 3rd October, 2019 to commemorate the 25th anniversary of Providence Heights Secondary School and the 35th anniversary of Providence Nursery and Primary School. Besides, the school has been an active and regular participant in the Lagos State Private School Athletics Competitions with many medals to its honour.</p>
                            
                            <h1 className="text-left" style={{ marginBottom : "20px" }}>
                                Subjects Offered - Senior Secondary
                            </h1>
                            <div className="row">
                                <div className="col-md-4">
                                    <h3 className="text-left" style={{ marginBottom : "20px" }}>
                                        Science
                                    </h3>
                                    <ul style={{marginLeft : "30px", marginBottom: "30px"}}>
                                        <li className="text-left">
                                            English Language
                                        </li>
                                        <li className="text-left">
                                            Mathematics
                                        </li>
                                        <li className="text-left">
                                            Civic Education
                                        </li>
                                        <li className="text-left">
                                            Physics
                                        </li>
                                        <li className="text-left">
                                            Chemistry
                                        </li>
                                        <li className="text-left">
                                            Biology
                                        </li>
                                        <li className="text-left">
                                            Animal Husbandry
                                        </li>
                                        <li className="text-left">
                                            Further Mathematics
                                        </li>
                                        <li className="text-left">
                                            Agric Science
                                        </li>
                                        <li className="text-left">
                                            Technical Drawing
                                        </li>
                                        <li className="text-left">
                                            Geography
                                        </li>
                                        <li className="text-left">
                                            French
                                        </li>
                                        <li className="text-left">
                                            Economics
                                        </li>
                                        <li className="text-left">
                                            ICT
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <h3 className="text-left" style={{ marginBottom : "20px" }}>
                                        Commercial
                                    </h3>
                                    <ul style={{marginLeft : "30px", marginBottom: "30px"}}>
                                        <li className="text-left">
                                            English Language
                                        </li>
                                        <li className="text-left">
                                            Mathematics
                                        </li>
                                        <li className="text-left">
                                            Civic Education
                                        </li>
                                        <li className="text-left">
                                            Economics
                                        </li>
                                        <li className="text-left">
                                            Marketing
                                        </li>
                                        <li className="text-left">
                                            Commerce
                                        </li>
                                        <li className="text-left">
                                            Financial Accounting
                                        </li>
                                        <li className="text-left">
                                            Government
                                        </li>
                                        <li className="text-left">
                                            Geography
                                        </li>
                                        <li className="text-left">
                                            Further Mathematics
                                        </li>
                                        <li className="text-left">
                                            French
                                        </li>
                                        <li className="text-left">
                                            ICT
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <h3 className="text-left" style={{ marginBottom : "20px" }}>
                                        Arts
                                    </h3>
                                    <ul style={{marginLeft : "30px", marginBottom: "30px"}}>
                                        <li className="text-left">
                                            English Language
                                        </li>
                                        <li className="text-left">
                                            Mathematics
                                        </li>
                                        <li className="text-left">
                                            Civic Education
                                        </li>
                                        <li className="text-left">
                                            Economics
                                        </li>
                                        <li className="text-left">
                                            French
                                        </li>
                                        <li className="text-left">
                                            ICT
                                        </li>
                                        <li className="text-left">
                                            Biology
                                        </li>
                                        <li className="text-left">
                                            Government
                                        </li>
                                        <li className="text-left">
                                            CCA
                                        </li>
                                        <li className="text-left">
                                            CCP
                                        </li>
                                        <li className="text-left">
                                            Dying &  Bleaching
                                        </li>
                                        <li className="text-left">
                                            Fine Art
                                        </li>
                                        <li className="text-left">
                                            Lit-in-English
                                        </li>
                                        <li className="text-left">
                                            Igbo
                                        </li>
                                        <li className="text-left">
                                            Yoruba
                                        </li>
                                        <li className="text-left">
                                            CRS
                                        </li>
                                        <li className="text-left">
                                            Islamic Studies
                                        </li>
                                        <li className="text-left">
                                            History
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <h1 className="text-left" style={{ marginBottom : "20px" }}>
                                Subjects Offered - Junior Secondary
                            </h1>
                            <div className="row">
                                <div className="col-md-4">
                                    <h3 className="text-left" style={{ marginBottom : "20px" }}>
                                        Jss3 Subjects
                                    </h3>
                                    <ol style={{marginLeft : "30px", marginBottom: "30px"}}>
                                        <li className="text-left">
                                            English Studies
                                        </li>
                                        <li className="text-left">
                                            Mathematics
                                        </li>
                                        <li className="text-left">
                                            Agricultural Science
                                        </li>
                                        <li className="text-left">
                                            Civic Education
                                        </li>
                                        
                                        <li className="text-left">
                                            French
                                        </li>
                                        
                                        <li className="text-left">
                                            ICT
                                        </li>
                                        <li className="text-left">
                                            Business Studies
                                        </li>
                                        <li className="text-left">
                                            Basic Science
                                        </li>
                                        <li className="text-left">
                                            Basic Technology
                                        </li>
                                        <li className="text-left">
                                            Home Economics
                                        </li>
                                        <li className="text-left">
                                            Physical and Health Education
                                        </li>
                                        <li className="text-left">
                                            CRS
                                        </li>
                                        <li className="text-left">
                                            IRS
                                        </li>
                                        <li className="text-left">
                                            Social Studies
                                        </li>

                                        <li className="text-left">
                                            Creative and Cultural Art
                                        </li>

                                        <li className="text-left">
                                            Yoruba Language
                                        </li>

                                        <li className="text-left">
                                            Music
                                        </li>

                                        <li className="text-left">
                                            Igbo Language
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </section>

            <section className="learning_part" style={{ backgroundColor:'#fff' }}>
                <div className="container">
                    <div className="row align-items-sm-center align-items-lg-stretch">
                        
                        <div className="col-12">
                            <div className="learning_member_text">
                                {/* <h5>COVID-19</h5> */}
                                <h2>ACADEMIC PROWESS OF THE SCHOOL </h2>
                                <p className='mb-5 text-left'>Providence Heights is a haven of academic excellence, a breeding ground of honest future leaders and above all, a place where parents pay relatively little and get much in return. This fact is buttressed with a summary of the West African Senior School Certificate Examination (WASSCE) results of the school for the past eighteen years i.e 2003 to 2020. The statistics is provided below:</p>
                                <img className="img-fluid" src={stats} alt="" />
                                <p className='mt-4 text-left'>From the above analysis, it shows that the school has been consistent in her academic pursuit over the years.</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            <section className="learning_part" style={{ backgroundColor:'#f5f5f5' }}>
                <div className="container">
                    <div className="row ">
                        
                        <div className="col-12">
                            <div className="learning_member_text">
                                <h5 className='text-left'>LIST OF SOME AWARDS AND PRIZES WON</h5>
                                <h2 className='text-left'>AWARDS AND PRIZES </h2>
                                <ol>
                                    <li className='text-left mb-4'>
                                        In the 2001, Cowbell National Competition (Mathematics) Adebayo Olubunmi Sekinat came first in Lagos State in the Senior category.
                                    </li>
                                    <li className='text-left mb-4'>
                                        In January 2001, Itata Emmanuel and Olagbaju Titilope came 2nd in the Zonal JETS Competition to qualify for the state level.
                                    </li>
                                    <li className='text-left mb-4'>
                                        In a debate organized by the Ifako Development Association and Iju Youths Association in 2002, the school emerged in 1st position.
                                    </li>
                                    <li className='text-left mb-4'>
                                        Over twelve of our former students enjoyed scholarships overseas: among them are Oyeyemi Oyekanmi, Unites States Presidential Scholarship and Ogunlowo Omolewa who had the highest score in the SAT all over Nigeria in 2002.
                                    </li>
                                    <li className='text-left mb-4'>
                                        In 2003, Koya Theophilus came third in the Lagos Mathematics Olympiad and went on to represent Lagos State at the National level alongside others.
                                    </li>
                                    <li className='text-left mb-4'>
                                        On the 27th May, 2006, Toye Adeola Mariam was honoured by the Lagos State Ministry of Education for representing the state and excelling in the National JETS Competition held in Akure in 2005. She came second at the Federal level.
                                    </li>
                                    <li className='text-left mb-4'>
                                        On 31st October, 2006, the school was honoured with merit awards as Malpractice Free school for the year 2005/2006.
                                    </li>
                                    <li className='text-left mb-4'>
                                        On an NTA Channel 5 Television Debate programme on June 15, 2007 “Co-education is Best for Nigerian Children” the school defeated others to emerge the winner.
                                    </li>
                                    <li className='text-left mb-4'>
                                        On an NTA Channel 5 Television Debate programme on March 12, 2009 on the topic “Parents should be allowed to choose a career for their children” the school defeated others to emerge winner.
                                    </li>
                                    <li className='text-left mb-4'>
                                        On a MITV Television debate Programme on Thursday, June 18, 2009 on the topic: “Petroleum is a curse, rather than a blessing in Nigeria” the school defeated others to emerge winner.
                                    </li>
                                    <li className='text-left mb-4'>
                                        The school came 1st in WAEC’s 60th Anniversary quiz competition organized by WAEC on Friday March 16, 2012.
                                    </li>
                                    <li className='text-left mb-4'>
                                        The school’s representatives placed 1st and 3rd respectively in the senior and junior categories of the 2013 Mathematics Olympiad organized by the Mathematics Association of Nigeria.
                                    </li>
                                    <li className='text-left mb-4'>
                                    The school came first in the senior category of the maiden edition of the secondary school competition organized by the Lagos State chapter of the National Association of the Proprietors of Private Schools, on the 25th of May, 2014.
                                    </li>
                                    <li className='text-left mb-4'>
                                    In the NTA ETV programme “Take a step” a science quiz competition held at NTA Channel 10, Tejuosho on the 18th of February, 2014, our school outshone the other schools to emerge winner.
                                    </li>
                                    <li className='text-left mb-4'>
                                    In another NTA ETV programme “Work it out” Mathematics quiz competition held at NTA Channel 10, Tejuosho on the 22nd of May, 2014, our school emerged 1st and 2nd positions in two separate competitions.
                                    </li>
                                    <li className='text-left mb-4'>
                                    In the quiz competition organized by African Church Model College during their literary week, Providence Heights triumphed over seven other schools to emerge overall winner.
                                    </li>
                                    <li className='text-left mb-4'>
                                    In the quiz competition organized by The Nigerian Society of Engineers, Ikeja Branch, on 25th June, 2014, for both private and public secondary schools in Ikeja, Ifako Ijaiye and Agege Local Government Areas, our school sailed through the various stages of the competition to emerge overall winner. 
                                    </li>
                                    <li className='text-left mb-4'>
                                    <strong> SCHOLARSHIP:</strong> Two of our 2013/2014 graduating students were offered scholarship by Management Education and Training (MET) Limited, Ikeja for their brilliant performance in the SAT (Scholastic Achievement Test) weeding scholarship test held on 21st June, 2014.
                                    </li>
                                    <li className='text-left mb-4'>
                                    <strong> IMPRESSIVE OUTING IN THE 2015 COWBELL MATHEMATICS COMPETITION <br></br></strong> Abhulimhen Caleb of Providence Heights Secondary School came 1st in Lagos State in the senior category of the 2015 Cowbell National Senior Secondary School Mathematics Competition held on 14th March, 2015. He subsequently represented Lagos State in the 2015 Cowbellpedia but lost in the semi-final stage.  
                                    </li>
                                    <li className='text-left mb-4'>
                                    <strong> OUTSTANDING PERFORMANCE IN THE 2015 NNPC QUIZ COMPETITION <br></br></strong> Abhulimhen Caleb came 1st in Lagos State in the 2015 NNPC Quiz Competition. He, together with the 1st and 2nd runners up, subsequently represented Lagos State in the South West Zone of the competition and came first. <br></br> The trio represented the South West Zone in the final stage of the NNPC Quiz Competition in Abuja on the 7th and 8th July, 2015 and emerged in 2nd position.
                                    </li>
                                    <li className='text-left mb-4'>
                                    Abhulimhen Caleb came 3rd in the senior category of the 2015 Mathematics Olympiad organized by the Mathematical Association of Nigeria (MAN)
                                    </li>
                                    <li className='text-left mb-4'>
                                    Abhulimhen Caleb also emerged in the 4th position in the Oluwole Awokoya Chemistry Competition held in June 2015 at the University of Lagos. 
                                    </li>
                                    <li className='text-left mb-4'>
                                        <strong> MET SCHOLARSHIP AWARDS <br></br></strong> As a result of the outstanding performance of our students in their previous scholarship Qualifying Examinations into American Universities, Management, Education and Training Ltd (MET) again gave our school 5 slots for the 2017/2018 scholarship qualifying examinations. The examination took place on Saturday 17th June 2017. 
                                        <br></br>
                                        Two of the students who had received the MET Preparatory Tuition Scholarship wrote the SAT in October 2016 and secured admission on full scholarship
                                        <br></br>
                                        <ol type='a'>
                                            <li> <strong> University of Alabama</strong>  in the United States:  <strong> AGEDE Oreoluwa</strong> to study Aeronautic Engineering and </li>
                                            <li> <strong> Howard University </strong>  in the United States:  <strong> ADEJOORIN Ifeoluwakiisi </strong> to study Aeronautic Engineering and </li>
                                        </ol>
                                    </li>
                                    <li className='text-left mb-4'>
                                        <strong> PROJECT SKILL 2017 AWARD <br></br></strong> The Nigeria Society of Engineers (Ikeja Branch) in conjunction with Young Engineers Edutainment Nig. Ltd. organized a 3-day Training, Quiz and Project exhibition for selected schools in the state in May 2017.
                                        <br></br>
                                        The school presented a Water Sachet Sealing Machine designed by a group of students ably supervised by the following teachers: Messrs. Olwauni, Oluwatobi and Oladipo. The ingenuity and the simplicity of the design swayed the judges and at the end, the school won the ultimate award. A beautifully designed plaque was presented to the school. 
                                        <br></br>
                                        This ultimate award qualified the school to represent Lagos State at the Science, Technology, Engineering and Mathematics Festival (STEM), an international project exhibition in September 2017.
                                    </li>
                                    <li className='text-left mb-4'>
                                        <strong> TOWUNMI COKER LITERARY FOUNDATION AWARD - 2017 <br></br></strong> 
                                        The body organized its annual creative writing competition for students in Prose and Poetry. The objective is to bring out the creativity inherent in these students. The students were specifically instructed to desist from plagiarizing other peoples’ works, either by copying from books or from the internet. Such entries would be disqualified.
                                        <br></br>
                                        <span className=' d-block mt-4'>
                                        Out of about 1000 entries received in 2017, <strong> OYEBOLA MARY’s</strong> entry for Poetry was selected for the <strong> 2nd runner-up Award</strong> for Poetry whilst <strong>OKEGBEMI TUNMISE’s</strong> entry for Prose, “The Nigeria of my Dream”, came 5th overall.
                                        </span>
                                    </li>
                                    <li className='text-left mb-4'>
                                        <strong> DEE-CHORD’S WINTER INTER-SCHOOLS’ MUSIC COMPETITION SEASON 2  <br></br></strong>
                                        The School Choir’s vocal conduct skills, instrumentation and stage craft won them the 1st runner-up position. The school was presented a Lead Guitar. At the season 1 in 2018, the school won a <strong> YAMAHA model complete drum set</strong>.
                                    </li>
                                    <li className='text-left mb-4'>
                                        <strong> AWARDS 2018  <br></br></strong>
                                        All in all we have cause to thank God for being gracious unto us with the success story of our students. Ours is limitless and that is why we again rejoice in being given this mighty award by <strong> PRESTIGE NIGERIA EDUCATION AWARDS AS THE BEST SECONDARY SCHOOL IN IFAKO-IJAIYE LOCAL GOVERNMENT FOR 2017.</strong>
                                        <span className=' d-block mt-4'>
                                        This is in recognition of our efforts in the delivery of quality education and commitment to excellence.
                                        </span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    );
}

export default ProvidenceHeightsAbout;
