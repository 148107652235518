import React, { useState,useEffect,useRef } from 'react';
// https://fslightbox.com/react/documentation/how-to-use for gallery
import FsLightbox from 'fslightbox-react'; 
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import gallery1 from '../img/gallery/DSC_1453.JPG'
import gallery2 from "../img/gallery/DSC_1463.JPG"
import gallery3 from "../img/gallery/DSC_1456.JPG"
import gallery4 from "../img/gallery/DSC_6089.JPG"
import gallery5 from "../img/gallery/DSC_6101.JPG"
import gallery6 from "../img/gallery/DSC_6103.JPG"
import gallery7 from "../img/gallery/DSC_6106.JPG"
import gallery8 from "../img/gallery/DSC_6123.JPG"
import gallery9 from "../img/gallery/DSC_6124.JPG"
import gallery10 from "../img/gallery/38.jpg"
import gallery11 from "../img/gallery/40.jpg"
import gallery12 from "../img/gallery/43.jpg"
import axios from 'axios';
import Carousel from 'react-tiny-slider'





const ProvidencePrimary = () => {

    const carousel = useRef(null)

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });
        
    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }
    React.useEffect(()=>{
        window.scrollTo(0, 0); 
    })

    return (
        <>
            <Navbar></Navbar>
            <section style={{marginTop:"90px"}}>
            </section>
            <section className="banner_part">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        
                        <div className="col-lg-6 col-xl-6 ">
                            <div className="banner_text pl-lg-5 pr-lg-5 pl-1 pr-1 ">
                                <div className="banner_text_iner">
                                    <h5 className='text-left'>Providence Primary</h5>
                                    <h1 className='text-left'>Making Your Childs
                                        World Better</h1>
                                    <p className='text-left'>We are open to you with an assurance we will be at your family’s side through courageous learning journey. I hope that you will come visit us and experience our schools unique and indutive community. </p>
                                    <div className="d-none d-lg-block text-left">
                                        <a href="/about" className="btn_1">About Us </a>
                                        <a href="/contact" className="btn_2">Contact Us </a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6">
                            <iframe style={{ width:"100%", height:"700px"}} src="https://www.youtube.com/embed/sWwmr-WIVVo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        
                    </div>
                </div>
            </section>
            
            <section className="feature_part">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-xl-3 align-self-center">
                            <div className="single_feature_text ">
                                <h2>Providence  <br/>Schools</h2>
                                <p >Providence Schools was established in 1984. The mission of the school is to raise a generation of young people who will break barriers, challenge the status quo in the quest to change their world. Located at No 165, Iju road Fagba, Ifako-Ijaiye, Lagos State.</p>
                                <a href="/about" className="btn_1">Read More</a>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="single_feature">
                                <div className="single_feature_part">
                                    <span className="single_feature_icon"><i className="ti-eye"></i></span>
                                    <h4>Our Vision</h4>
                                    <p>A generation of people that will not see limitations to excellence.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="single_feature">
                                <div className="single_feature_part">
                                    <span className="single_feature_icon"><i className="ti-stats-up"></i></span>
                                    <h4>Our Mission</h4>
                                    <p>It is commitment to raise a generation of young people who will break barriers, challenges the status quo in the quest to change their world.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-3">
                            <div className="single_feature">
                                <div className="single_feature_part single_feature_part_2">
                                    <span className="single_service_icon style_icon"><i className="ti-light-bulb"></i></span>
                                    <h4>OUR CORE VALUES</h4>
                                    <p>• Love • Integrity • Teamwork • Excellence • Respect </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="learning_part">
                <div className="container">
                    <div className="row" style={{alignItems: "center"}}>
                        <div className="col-md-6 col-lg-6">
                            <div style={{alignItems: "center", height:"100%", display:"flex",alignItems: "center"}} className="learning_img">
                                <img src={gallery7} alt=""/>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="learning_member_text">
                                    <p style={{ fontSize:"18px", textTransform:"uppercase"}}>About us</p>

                                {/* <h2>Learning with Love and Laughter</h2>
                                <p>Providence schools opened it doors with 27 pupils enrolled in kindergarten through grade six. Since its opening, the school’s dynamic educational environment has continued to evolved. Soon after the school began, word started to spread. In just three years, enrolment tripled. The school was initially housed in a modular building; additional land was acquired for it to grow into. With a science laboratory, music room, ICT (NIIT). Providence schools was approved in 1984 as we were recipients of several awards. </p>
                                <p>In 1984 September, we opened our dormitory and welcomed boarding students in grade five and six this beautiful downtown facility with forty-seven students in both the boys’ and girls’ hostel.</p>
                                <a href="/about" className="btn_1">Read More</a> */}
                                <h2>Caring And Experienced Staff Make All The Difference</h2>
                                <p>Our teachers, both primary Early Years Foundational Stage (EYFS) and specialists in art, music, language and P.E have a minimum of 5+ years of experience (many with much more!) teaching certifications/credentials and IT training.
                                    Collaboration among our primary teachers and specialist teachers ensures that pupils can see the connection between concepts, other subjects and the real world. Each year, our teachers and administration reflect upon and refine our curriculum to ensure we are meeting the need of students. Just as our word and our students are ever-evolving, our teaching practices evolve.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="learning_part">
                <div className="container">
                    <div className="row align-items-sm-center align-items-lg-stretch">
                        <div className="col-md-7 col-lg-7">
                            <div className="">
                                <img className="img-fluid" src="img/teacher-animate2.svg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-5">
                            <div className="learning_member_text"> 
                                <h5>About us</h5>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <div className="section-top-border">
                <div className="container">
                    <FsLightbox toggler={lightboxController.toggler} slide={lightboxController.slide} 
                        sources={[                            
                            gallery1, gallery2,  gallery3,  gallery4,  gallery5,  gallery6,  gallery7,  gallery8,  gallery9,  gallery10,  gallery11,  gallery12                       
                        ]}
                    /> 
                    <div  className="row gallery-item">
                        <div className="col-md-4">
                            <a onClick={() => openLightboxOnSlide(1)}>
                                <div className="single-gallery-image" style={{background: "url("+gallery1+")"}}></div>
                            </a>
                        </div>
                        
                        <div className="col-md-4">
                            <a onClick={() => openLightboxOnSlide(2)}>
                                <div className="single-gallery-image" style={{background: "url("+gallery2+")"}}></div>
                            </a>
                        </div>
                        
                        <div className="col-md-4">
                            <a onClick={() => openLightboxOnSlide(3)}>
                                <div className="single-gallery-image" style={{background: "url("+gallery3+")"}}></div>
                            </a>
                        </div>
                        
                        <div className="col-md-4">
                            <a onClick={() => openLightboxOnSlide(4)}>
                                <div className="single-gallery-image" style={{background: "url("+gallery4+")"}}></div>
                            </a>
                        </div>
                        
                        <div className="col-md-4">
                            <a onClick={() => openLightboxOnSlide(5)}>
                                <div className="single-gallery-image" style={{background: "url("+gallery5+")"}}></div>
                            </a>
                        </div>
                        
                        <div className="col-md-4">
                            <a onClick={() => openLightboxOnSlide(6)}>
                                <div className="single-gallery-image" style={{background: "url("+gallery6+")"}}></div>
                            </a>
                        </div>
                        
                        <div className="col-md-4">
                            <a onClick={() => openLightboxOnSlide(7)}>
                                <div className="single-gallery-image" style={{background: "url("+gallery7+")"}}></div>
                            </a>
                        </div>
                        
                        <div className="col-md-4">
                            <a onClick={() => openLightboxOnSlide(8)}>
                                <div className="single-gallery-image" style={{background: "url("+gallery8+")"}}></div>
                            </a>
                        </div>

                        <div className="col-md-4">
                            <a onClick={() => openLightboxOnSlide(9)}>
                                <div className="single-gallery-image" style={{background: "url("+gallery9+")"}}></div>
                            </a>
                        </div>

                        <div className="col-md-4">
                            <a onClick={() => openLightboxOnSlide(10)}>
                                <div className="single-gallery-image" style={{background: "url("+gallery10+")"}}></div>
                            </a>
                        </div>

                        <div className="col-md-4">
                            <a onClick={() => openLightboxOnSlide(11)}>
                                <div className="single-gallery-image" style={{background: "url("+gallery11+")"}}></div>
                            </a>
                        </div>

                        <div className="col-md-4">
                            <a onClick={() => openLightboxOnSlide(12)}>
                                <div className="single-gallery-image" style={{background: "url("+gallery12+")"}}></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            
            <Footer></Footer>
        </>
    );
}

export default ProvidencePrimary;
