import React, {useContext} from 'react';
import {  Link } from 'react-router-dom';
import {AuthContext} from "../App"
import {AuthRedContext} from "../App"
import { useHistory, useLocation } from 'react-router-dom'; 
import provSchools from "../img/Logos/Providence_Schools.png"
import provHeight from "../img/Logos/Providence_height.png"

const Navbar = () => {
    const useTest =  useContext(AuthContext);
    const authstate =  useContext(AuthRedContext);
    let history = useHistory();
    let location = useLocation();

   
    return (
        <header className="main_menu home_menu">
            <div className="container-fluid ">
                <div className="row align-items-center">
                    <div className="col-lg-12">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <a className="navbar-brand" href="index.html"> 
                                {/* <img className="mr-2 mr-md-5" width='50' src={ location.pathname == "/providenceSecondary" ? provHeight : window.location.origin + '/img/ProvidenceLogo.png'} alt="logo" /> */}
                                <img className="mr-2 mr-md-3" width='50' src={provHeight} alt="logo" />
                                <img className="mr-2 mr-md-3" width='50' src={ provSchools} alt="logo" />
                                {/* <span className={
                                    location.pathname == "/contact" || 
                                    location.pathname == "/about" || 
                                    location.pathname == "/providenceHeights/about" || 
                                    location.pathname == "/providenceHeights/gallery" || 
                                    location.pathname == "/gallery"  ? "text-white" : "" } >Providence Schools</span>   */}
                                </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse main-menu-item justify-content-end" id="navbarSupportedContent">
                                <ul className="navbar-nav align-items-center mt-4 mt-lg-0">
                                    <li className="nav-item">
                                        {/* <a className="nav-link" href="index.html">Home</a> */}
                                        <Link style={{color:'#4caf4f'}} className="nav-link" to="/">Home</Link> 
                                    </li>

                                    <li className="nav-item">
                                        {/* <a className="nav-link" href="index.html">Home</a> */}
                                        <Link style={{color:'#4caf4f'}} className="nav-link" to="/providencePrimary">Providence Primary</Link> 
                                    </li>

                                    <li className="nav-item">
                                        {/* <a className="nav-link" href="index.html">Home</a> */}
                                        <Link style={{color:'#4caf4f'}} className="nav-link" to="/providenceSecondary">Providence Heights</Link> 
                                    </li>

                                    <li className="nav-item">
                                        {/* <a className="nav-link" href="about.html">About</a> */}
                                        <Link style={{color:'#4caf4f'}} className="nav-link" to="/providenceHeights/about">About</Link> 
                                    </li>
                                    
                                    <li className="nav-item">
                                        {/* <a className="nav-link" href="gallery.html">Gallery</a> */}
                                        <Link style={{color:'#4caf4f'}} className="nav-link" to="/providenceHeights/gallery">Gallery</Link> 
                                    </li>
                                    {
                                        authstate.isAuth? (
                                        <>
                                            <li className="nav-item">
                                                <Link style={{color:'#4caf4f'}} className="nav-link" to="/posts">Posts</Link> 
                                            </li>
                                            <li className="nav-item">
                                                <Link style={{color:'#4caf4f'}} className="nav-link" to="/create/post">Create Posts</Link> 
                                            </li>
                                            {/* <li className="d-none d-lg-block">
                                                <button style={{color:'#4caf4f'}} className="nav-link" onClick={()=>useTest.logout(history)}>Logout</button> 
                                            </li> */}
                                        </>
                                        ) :(
                                            <>
                                                
                                            </>
                                        )
                                    }
                                    <li className="nav-item d-block d-lg-none mb-4">
                                        {/* <a className="nav-link" href="gallery.html">Gallery</a> */}
                                        <Link style={{color:'#4caf4f'}} className="nav-link" to="/contact">Admission</Link> 
                                    </li>
                                    <li className="d-none d-lg-block ">
                                        {/* <a className="btn_1" href="contact.html">Contact Us</a> */}
                                        <Link className="btn_1" to="/contact">Admission</Link> 
                                    </li>
                                    
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Navbar;
