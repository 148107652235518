import React, {useState} from 'react';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const CreatePost = () => {
    const [form, setForm ] = useState({
        title: '',
        description:'',
        image:'',
        loading:false
    });
    const [errors,setErrors] = useState({})
    const [success,setSuccess] = useState("")

    const submit = (e) => {
        e.preventDefault()
        const theForm = new FormData();
        theForm.append('title', form.title);
        theForm.append('description', form.description);
        theForm.append('image', form.image);
        setForm({...form,loading:true})

        axios.defaults.withCredentials = true;
        // axios.get('/sanctum/csrf-cookie')
        // .then(response => {
           
        // }).catch(error => {
        //     console.log(error)
        //     setForm({...form,loading:false})
        // });

        axios.post('/api/post/create',theForm)
        .then(res => {
            setForm({
                title: '',
                description:'',
                image:'',
                loading:false
            })
            alert(res.data.message)
            setSuccess(res.data.message)
            
        }).catch(error => {
            setErrors(error.response.data.errors)
            setForm({...form,loading:false})
        })
        
    }

    return (
        <>
            <Navbar></Navbar>
            <section className="contact-section section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="contact-title mb-5">Create Post</h2>
                        </div>
                        <div className="col-lg-8 offset-lg-2">
                            <form  onSubmit={(e)=>submit(e)} className="form-contact contact_form" method="post" >
                                <div className="row">
                                    {
                                        success?(
                                            <div className="alert alert-success w-100">
                                                <h4>{success}</h4>
                                            </div>
                                        ):(<></>)
                                    }
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea value={form.description} className="form-control w-100" onChange={(e)=> setForm({...form,description: e.target.value })} name="description" id="message" cols="30" rows="9" placeholder='Post Details' required></textarea>
                                            {
                                                errors.description? (
                                                    <div className=" ">
                                                        <p className="text-left text-danger"> {errors.description[0]} </p> 
                                                    </div>
                                                ):(<></>)
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input value={form.title} className="form-control" onChange={(e)=> setForm({...form,title: e.target.value })} name="subject" id="subject" type="text"  placeholder='Post Title' required/>
                                            {
                                                errors.title? (
                                                    <div className=" ">
                                                        <p className="text-left text-danger"> {errors.title[0]} </p> 
                                                    </div>
                                                ):(<></>)
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                        {
                                                errors.image? (
                                                    <div className=" ">
                                                        <p className="text-left text-danger"> {errors.image[0]} </p> 
                                                    </div>
                                                ):(<></>)
                                            }
                                            <input className="form-control" onChange={(e)=> setForm({...form,image: e.target.files[0] })} name="image" id="subject" type="file" placeholder='Select Image' required/>
                                            <p className="text-left">Post Image</p> 
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mt-3 ">
                                    {
                                        form.loading? (
                                            <button   className="button button-contactForm btn_1 px-5">Creating</button>
                                        ):(
                                            <button  type="submit" className="button button-contactForm btn_1 px-5">Create Post</button>
                                        )
                                    }
                                    
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    );
}

export default CreatePost;
