import React, { useState, useEffect,useRef } from 'react';
// https://www.npmjs.com/package/react-tiny-slider
import Carousel from 'react-tiny-slider'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';



const About = () => {
    const carousel = useRef(null)

    useEffect(()=>{
        window.scrollTo(0, 0); 
    })

    return (
        <>
            <Navbar></Navbar>
            <section className="breadcrumb " style={{backgroundImage: 'url(img/gallery/DSC_1462.JPG)', backgroundPosition: 'top', backgroundSize: 'cover',}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb_iner text-center">
                                <div className="breadcrumb_iner_item">
                                    <h2>About Us</h2>
                                    <p>Home<span>/</span>About Us</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className=' d-flex'  >
                <a href='/providenceHeights/about' className='py-4 w-50' style={{backgroundColor: '#f5f5f5',color: 'rgb(76, 175, 79)'}}>PROVIDENCE HEIGHTS ‘PH’ SECONDARY SCHOOL</a>
                <a href='/about' className='py-4  text-white w-50' style={{backgroundColor: 'rgb(76, 175, 79)'}}>PROVIDENCE SCHOOLS</a>
            </div>

            <section className="feature_part single_feature_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-xl-3 ">
                            <div className="single_feature_text ">
                                <h2>Providence  <br /> Schools</h2>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xl-9">
                            <p className="mb-30 text-left">Providence Schools was established in 1984. The mission of the school is to raise a generation of young people who will break barriers, challenge the status quo in the quest to change their world. Located at No 165, Iju road Fagba, Ifako-Ijaiye, Lagos State.
                                We are open to you with an assurance we will be at your family’s side through courageous learning journey. I hope that you will come visit us and experience our schools unique and leading community.
                            </p>
                            <p className="mb-30 text-left">
                                Providence today occupies a front line position in the education sector in Ifako-Ijaiye, Lagos state, Nigeria.
                            </p>
                            {/* <p className="mb-30 text-left">We have learned that “the Providence Schools’ way” providing the best possible learning experience for students in an environment that clearly reflects a strong sense of care. Our pupils think broadly and deeply, as we connect experiences and relationships across disciplines and international boundaries. </p>
                            <p className="mb-30 text-left">We are open to you with an assurance we will be at your family’s side through courageous learning journey. We hope that you will come visit us and experience our school's unique and indutive community.</p> */}

                            <h3 className="text-left" style={{ marginBottom : "20px" }}>
                                Future Plans For The School
                            </h3>
                            <p className="mb-30 text-left " >
                                To continue to raise a generation of pupils who will break barriers, challenge the status quo in the quest to change their world. Located at 165, Iju Road, Fagba, Agege, Lagos State.
                            </p>

                            <h3 className="text-left" style={{ marginBottom : "20px" }}>
                                Calendar Of School Activities For The Current Year
                            </h3>
                            <ul style={{marginLeft : "30px", marginBottom: "30px"}}>
                                <li className="text-left">
                                    • Anniversary Year
                                </li>
                                <li className="text-left">
                                    • Science/Art Exhibition
                                </li>
                                <li className="text-left">
                                    • Club Day
                                </li>
                                <li className="text-left">
                                    •Inter House Competition
                                </li>
                                <li className="text-left">
                                    • Prize Giving Day
                                </li>
                                <li className="text-left">
                                    • Concert
                                </li>
                                <li className="text-left">
                                • Charity Week
                                </li>
                            </ul>

                            <h3 className="text-left" style={{ marginBottom : "20px" }}>
                                Famous Past Pupils/Students
                            </h3>
                            <ul style={{marginLeft : "30px", marginBottom: "30px"}}>
                                <li className="text-left">
                                	Opalemo Omotola – Best graduating pupil
                                </li>
                                <li className="text-left">
                                	Oniyamali Efe – 1st position under ten (10) Lagos State organized championship
                                </li>
                                <li className="text-left">
                                     William Omogbai – Best student in entrance examination Nationwide
                                </li>
                                <li className="text-left">
                                    Obazu Precious
                                </li>
                                <li className="text-left">
                                    Opalemo 
                                </li>
                            </ul>

                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="text-left" style={{ marginBottom : "20px" }}>
                                        Subjects Offered 
                                    </h3>
                                    <ul style={{marginLeft : "30px", marginBottom: "30px"}}>
                                        <li className="text-left">
                                            Literacy
                                        </li>
                                        <li className="text-left">
                                            Numeracy
                                        </li>
                                        <li className="text-left">
                                            Science
                                        </li>
                                        <li className="text-left">
                                            Religious Education
                                        </li>
                                        <li className="text-left">
                                            Social Studies
                                        </li>
                                        <li className="text-left">
                                                Creative Arts
                                        </li>
                                        <li className="text-left">
                                                Music
                                        </li>
                                        <li className="text-left">
                                                Diction
                                        </li>
                                        <li className="text-left">
                                                Reasoning
                                        </li>
                                        <li className="text-left">
                                                Physical Education
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <h3 className="text-left" style={{ marginBottom : "20px" }}>
                                        Club & Societies
                                    </h3>
                                    <ul style={{marginLeft : "30px", marginBottom: "30px"}}>
                                        <li className="text-left">
                                            Jets Club
                                        </li>
                                        <li className="text-left">
                                                Taekwondo
                                        </li>
                                        <li className="text-left">
                                                Music
                                        </li>
                                        <li className="text-left">
                                                Literacy/Debating
                                        </li>
                                        <li className="text-left">
                                                Art/Designs
                                        </li>
                                        <li className="text-left">
                                                    Home Makers
                                        </li>
                                        <li className="text-left">
                                                    Life Science
                                        </li>
                                        <li className="text-left">
                                                    Ballet
                                        </li>
                                        <li className="text-left">
                                                    Red Cross
                                        </li>
                                        <li className="text-left">
                                                    Providence Delight
                                        </li>

                                        <li className="text-left">
                                                        Spelling Bee
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            


                            


                            <h3 className="text-left" style={{ marginBottom : "20px" }}>
                                Management Team
                            </h3>
                            <ul style={{marginLeft : "30px", marginBottom: "30px"}}>
                                <li className="text-left">
                                 	Mr Adedokun Oduyemi
                                </li>
                                <li className="text-left">
                                    Mrs Bridget Nunoo
                                </li>
                                <li className="text-left">
                                 	Mr Calys-Tagoe Frederic 
                                </li>
                                
                            </ul>

                        </div>
                        {/* <div className="col-sm-6 col-xl-9">

                        </div> */}
                    </div>
                </div>
            </section>

            

            <section className="learning_part">
                <div className="container">
                    <div className="row align-items-sm-center align-items-lg-stretch">
                        
                        <div className="col-md-5 col-lg-7">
                            <div className="learning_member_text">
                                <h5>COVID-19</h5>
                                <h2>RIS RESPONSE TO COVID-19</h2>
                                <p>Providence schools’ priority is to ensure the safety of students and learning environment, minimize risk, protect academic learning and implement appropriate learning experiences due to the exceptional circumstance created by COVID-19.</p>
                                <p>Our blended learning plan outlines the program expectations and safety protocols for the start of 2020/2021 school year. Safety of the community, quality of work programs and flexibility to address environmental changes, all the while providing continuity of learning are the overarching principles behind this plan. We appreciate your time reviewing this information so you can be informed as to how we will continue to provide an outstanding, safe student-centered education this year.</p>
                                <img className="img-fluid" src="img/social-distance-at-school-animate.svg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-5">
                            <div className="">
                                <img className="img-fluid" src="img/kids-using-hand-sanitizer-at-school-animate.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="testimonial_part section_padding">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-xl-5">
                            <div className="section_tittle text-center">
                                <p>tesimonials</p>
                                <h2>Happy Students</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                        <Carousel swipeAngle={false} items={1} mouseDrag ref={carousel} autoplay={true} controls={true} nav={false} > 
                                <div className="testimonial_slider ">
                                    <div className="row">
                                        <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                            <div className="testimonial_slider_text">
                                                <p>At Providence schools I have become exactly the person I wanted to be.</p>
                                                <h4>-- Engr. Owotomi Gbolagade</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                            <div className="testimonial_slider_text">
                                                <p>Thank you for fostering the kind of learning community that most only dream of </p>
                                                <h4>-- Adetutu Savage 2001</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial_slider">
                                    <div className="row">
                                        <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                            <div className="testimonial_slider_text">
                                                <p>Providence Schools really prepared me. A lot of my classes at Providence had some of the same content as I’m learning now in college.</p>
                                                <h4>-- Nwachukwu Uche 2013</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                            <div className="testimonial_slider_text">
                                                <p>The Providence Schools gave me the fullest enthusiasm for life learning and it got me into the college of my dreams.</p>
                                                <h4>-- Barr. O. Osiyale Osifalujo</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial_slider">
                                    <div className="row">
                                        <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                            <div className="testimonial_slider_text">
                                                <p>The Providence schools has an amazing ability to attract top people who work for the love of teaching. The school has given me a tremendous amount of freedom to pursue knowledge and experiences far beyond that of a typical high school, and has challenged me to explore my interests in great depth.</p>
                                                <h4>-- Dr Okifun Gregory</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                            <div className="testimonial_slider_text">
                                                <p>I gained self-responsibility and personal empowerment… I loved attending Providence Schools and miss it immensely.</p>
                                                <h4>-- Nita E. Nunio 2019</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial_slider">
                                    <div className="row">
                                        <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                            <div className="testimonial_slider_text">
                                                <p>Not only does the school prepare us for college and the outside world, but also how to deal with life as a whole.</p>
                                                <h4>-- Dr Ogundare Adewale, Alumni, class of ’95</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                            <div className="testimonial_slider_text">
                                                <p>I cannot imagine feeling any more satisfied with my life than I do right now, but if I am able to inspire students the way you have inspired me, my guess is that I will.</p>
                                                <h4>-- Dr Adekoya Adeyosoye</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial_slider">
                                    <div className="row">
                                        <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                            <div className="testimonial_slider_text">
                                                <p>I can’t believe I can like a school so much.</p>
                                                <h4>-- Engr. Dairo Olaoluwa</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                            <div className="testimonial_slider_text">
                                                <p>The creativity and resourcefulness I had was unlocked at Providence schools. I love the connections with teachers.</p>
                                                <h4>-- Iria Ogbeide Joseph 2002</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="testimonial_slider">
                                    <div className="row">
                                        <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                            <div className="testimonial_slider_text">
                                                <p>I love this school. It feels like home</p>
                                                <h4>-- Shobande Treasure</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </Carousel>
                        </div>
                    </div>
                </div>
            </section> */}
            <Footer></Footer>
        </>
    );
}

export default About;
