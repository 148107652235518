import React,{useEffect,useState} from 'react';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import {  Link } from 'react-router-dom';


const Posts = () => {
    const [post, setPost] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        window.scrollTo(0, 0); 
        axios.get('/api/posts')
            .then(res => {
                console.log(res.data)
                setPost(res.data)
                setLoading(false)

                console.log(JSON.stringify(post))
            }).catch(error => {
                setLoading(true)
            })
    },[])

    return (
        <>
            <Navbar></Navbar>
            <section className="blog_part section_padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-5">
                            <div className="section_tittle text-center">
                                <h2 className="contact-title mb-5">Posts</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    {
                        loading == false && post.length > 0 ?
                        (
                            post.map((item,index)=>{
                                return (
                                    <div className="col-sm-6 col-lg-4 col-xl-4" key={item.id}>
                                        <div className="single-home-blog">
                                            <div className="card">
                                                <img src={item.image} className="card-img-top" alt="blog"/>
                                                <div className="card-body">
                                                    <Link  to={'/posts/'+item.id}>
                                                        <h5 className="card-title">{item.title}</h5>

                                                    </Link>
                                                   
                                                    <p> {item.description} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            
                        ):
                        (
                            <h3 className="text-center mx-auto text-black-50">No Post.</h3>
                        )
                    }
                        
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    );
}

export default Posts;
