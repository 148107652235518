import logo from './logo.svg';
import './App.css';
import React,{useReducer} from 'react';
import ProvidencePrimary from './pages/ProvidencePrimary';
import ProvidenceSecondary from './pages/ProvidenceHeights';
import Home from './pages/Home'
import About from './pages/About'
import ProvidenceHeightsAbout from './pages/ProvidenceHeightsAbout'
import Gallery from './pages/Gallery';
import ProvidenceHeightsGallery from './pages/ProvidenceHeightsGallery'
import Contact from './pages/Contact';
import Login from './pages/Login';
import CreatePost from './pages/protected/CreatePost';
import Posts from './pages/protected/Posts';
import EditPost from './pages/protected/EditPost';
import { BrowserRouter as Router, Route, Link, Switch, useHistory , Redirect } from 'react-router-dom'; 
import axios from 'axios';

export const AuthContext = React.createContext();
export const AuthRedContext = React.createContext();

axios.defaults.baseURL = 'http://127.0.0.1:8000/';
// axios.defaults.baseURL = 'https://providenceapi.recaltd.com/';




//Initialize Login Values
const initialState = {
    isAuth: false,
    auth:{}
};

// Reducer Method
function Authreducer(state, action) {
  switch (action.type) {
    case 'signIn':
        sessionStorage.setItem("isAuth",true);
        return {
                ...state,
                isAuth: true,
                auth: JSON.parse(action.user)
            };
    case 'signOut':
        sessionStorage.setItem("isAuth",false);
        return {
            ...state,
            isAuth: false,
            auth: {}
        };

    case 'restore':
        // sessionStorage.setItem("isAuth",false);
        return {
            ...state,
            isAuth: true,
            auth: JSON.parse(action.user)
        };
    default:
      return {state}
  }
}



function App() {

    React.useEffect(()=>{
        // axios.defaults.withCredentials = true;
        // axios.defaults.headers["Access-Control-Allow-Origin"] = true;
        // sessionStorage.setItem('isAuth',false)
        // sessionStorage.setItem('auth','')

        if(sessionStorage.getItem('isAuth') == "true"){
            authdispatch({type:'restore', user:sessionStorage.getItem('auth')})
        }else if(sessionStorage.getItem('isAuth') == "false"){
            authdispatch({type:'signOut'})
        }
        
        return () =>{
            sessionStorage.setItem("isAuth",false);
        }
    },[])

    // Initializing Reducerr
    const [authstate, authdispatch] = useReducer(Authreducer, initialState);
 
    //initialize history
    let history = useHistory();

    const AuthContextData = {
        login : (history,user) =>{
            console.log("coming from App")
            sessionStorage.setItem("auth",JSON.stringify(user));
            authdispatch({type:'signIn',user:JSON.stringify(user) })
            history.push("/");
        },
        logout : (history)=>{
            // axios.get('https://providenceapi.recaltd.com/sanctum/csrf-cookie')
            // .then(response => {
            //     axios.post('https://providenceapi.recaltd.com/logout')
            //     .then(res => {
            //         sessionStorage.setItem("isAuth","");
            //         authdispatch({type:'signOut',user:"" })
            //         history.push("/");
            //     }).catch(error => {
            //         console.log(error)
            //     })
            // }).catch(error => {
            //     console.log(error)
            // });
        }
    }


  return (
    <AuthContext.Provider value={AuthContextData}>
        <AuthRedContext.Provider value={authstate}>
            <Router> 
                <div className="App">
                    <Switch> 
                        <Route exact path='/' component={Home}></Route> 
                        <Route exact path='/providencePrimary' component={ProvidencePrimary}></Route>
                        <Route exact path='/providenceSecondary' component={ProvidenceSecondary}></Route>
                        
                        <Route exact path='/about' component={About}></Route>
                        <Route exact path='/providenceHeights/about' component={ProvidenceHeightsAbout}></Route>
                        <Route exact path='/gallery' component={Gallery}></Route> 
                        <Route exact path='/providenceHeights/gallery' component={ProvidenceHeightsGallery}></Route>
                        <Route exact path='/contact' component={Contact}></Route>
                        <Route exact path='/login' component={Login}></Route>
                        <Route exact path='/create/post' component={CreatePost}></Route>
                        <Route exact path='/posts' component={Posts}></Route>
                        <Route exact path='/posts/:slug' component={EditPost}></Route>
                    
                        {/* {
                            authstate.isAuth ? (
                            <>
                                
                            </>
                            ) :(<></>)
                        }  */}
                    </Switch> 
                    
                </div>
            </Router> 
        </AuthRedContext.Provider>
    </AuthContext.Provider>  
    
  );
}

export default App;
