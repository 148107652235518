import React, {useState,useEffect} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; 
import { useHistory } from 'react-router-dom'; 
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const EditPost = () => {
    let { slug } = useParams();
    let history = useHistory();

    const [form, setForm ] = useState({
        title: '',
        description:'',
        image:'',
        loading:false
    });
    const [errors,setErrors] = useState({})
    const [success,setSuccess] = useState("")
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        axios.get('/api/posts/'+slug)
            .then(res => {
                console.log(res.data)
                setForm(res.data)
                setLoading(false)
            }).catch(error => {
                setLoading(true)
            })
    },[])

    const submit = (e) => {
        e.preventDefault()
        const theForm = new FormData();
        theForm.append('title', form.title);
        theForm.append('description', form.description);
        theForm.append('image', form.image);
        setForm({...form,loading:true})
        axios.post('/api/posts/'+slug,theForm)
        .then(res => {
            console.log(res.data)
            alert(res.data.message)
            setSuccess(res.data.message)
            setForm({...form,loading:false})
        }).catch(error => {
            setErrors(error.response.data.errors)
            setForm({...form,loading:false})
        })
        
    }

    const deletePost = (history)=>{
        axios.post('/api/posts/'+slug+'/delete')
            .then(res => {
                console.log(res.data)
                alert(res.data.message)
                setLoading(true)
                history.push('/posts')
            }).catch(error => {
                setErrors(error.response.data.errors)
                setLoading(false)
            })
    }

    return (
        <>
            <Navbar></Navbar>
            <section className="contact-section section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="contact-title mb-5">Edit Post</h2>
                        </div>
                        <div className="col-lg-8 offset-lg-2">
                        {
                        loading == false ?
                        (
                            <form  onSubmit={(e)=>submit(e)} className="form-contact contact_form" method="post" id="contactForm" noValidate="novalidate">
                                <div className="row">
                                    {
                                        success?(
                                            <div className="alert alert-success w-100">
                                                <h4>{success}</h4>
                                            </div>
                                        ):(<></>)
                                    }
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea value={form.description} className="form-control w-100" onChange={(e)=> setForm({...form,description: e.target.value })} name="description" id="message" cols="30" rows="9" placeholder='Post Details' required>  </textarea>
                                            {
                                                errors.description? (
                                                    <div className=" ">
                                                        <p className="text-left text-danger"> {errors.description[0]} </p> 
                                                    </div>
                                                ):(<></>)
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input className="form-control" value={form.title} onChange={(e)=> setForm({...form,title: e.target.value })} name="title" type="text"  placeholder='Post Title' required/>
                                            {
                                                errors.title? (
                                                    <div className=" ">
                                                        <p className="text-left text-danger"> {errors.title[0]} </p> 
                                                    </div>
                                                ):(<></>)
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                        <img width="200" src={form.image} className="" alt="blog"/>
                                        {
                                                errors.image? (
                                                    <div className=" ">
                                                        <p className="text-left text-danger mt-3"> {errors.image[0]} </p> 
                                                    </div>
                                                ):(<></>)
                                            }
                                            <input className="form-control mt-3" onChange={(e)=> setForm({...form,image: e.target.files[0] })} name="image" id="subject" type="file" placeholder='Select Image' required/>
                                            <p className="text-left">Post Image</p> 
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mt-3 ">
                                    {
                                        form.loading? (
                                            <button   className="button button-contactForm btn_1 px-5">Update</button>
                                        ):(
                                            <>
                                                <button  type="submit" className="button button-contactForm btn_1 px-5">Update Post</button>
                                                <button onClick={()=>deletePost(history)} type="button" className="button button-contactForm btn-danger px-5">Delete Post</button>
                                            </>
                                        )
                                    }
                                    
                                </div>
                            </form>
                            
                        ):
                        (
                            <h3 className="text-center mx-auto text-black-50">No Post</h3>
                        )
                    }
                            
                        </div>
                        
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    );
}

export default EditPost;
