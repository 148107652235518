
// https://fslightbox.com/react/documentation/how-to-use for gallery
import React, { useState,useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import FsLightbox from 'fslightbox-react'; 
import gallery1 from "../img/gallery/DSC_1453.JPG"
import gallery2 from "../img/gallery/DSC_1463.JPG"
import gallery3 from "../img/gallery/DSC_1456.JPG"
import gallery4 from "../img/gallery/33.jpg"
import gallery5 from "../img/gallery/35.jpg"
import gallery6 from "../img/gallery/36.jpg"
import gallery7 from "../img/gallery/46.jpg"
import gallery8 from "../img/gallery/35.jpg"
import gallery9 from "../img/gallery/36.jpg"
import gallery10 from "../img/gallery/38.jpg"
import gallery11 from "../img/gallery/40.jpg"
import gallery12 from "../img/gallery/43.jpg"

import gallery13 from "../img/gallery/DSC_6089.JPG"
import gallery14 from "../img/gallery/DSC_6101.JPG"
import gallery15 from "../img/gallery/DSC_6103.JPG"

import gallery16 from "../img/gallery/DSC_6106.JPG"
import gallery17 from "../img/gallery/DSC_6123.JPG"
import gallery18 from "../img/gallery/DSC_6124.JPG"


const Gallery = () => {
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    useEffect(()=>{
        window.scrollTo(0, 0); 
    })
        
    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        } );
    }

    
    return (
        <>
            <Navbar></Navbar>
            <section class="breadcrumb " style={{ backgroundImage: 'url(../img/gallery/DSC_1462.JPG)', backgroundPosition: 'top', backgroundSize: 'cover', }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="breadcrumb_iner text-center">
                                <div class="breadcrumb_iner_item">
                                    <h2>Our Gallery</h2>
                                    <p>Home<span>/</span>Gallery</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className=' d-flex'  >
                <a href='/providenceHeights/gallery' className='py-4 w-50' style={{backgroundColor: '#f5f5f5',color: 'rgb(76, 175, 79)'}}>PROVIDENCE HEIGHTS ‘PH’ SECONDARY SCHOOL</a>
                <a href='/gallery' className='py-4  text-white w-50' style={{backgroundColor: 'rgb(76, 175, 79)'}}>PROVIDENCE SCHOOLS</a>
            </div>

            <section class="feature_part single_feature_padding" style={{ marginBottom: '50px' }}>
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-xl-3 align-self-start">
                            <div class="single_feature_text ">
                                <h2>Providence  <br/> Schools Gallery</h2>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xl-9">                  
                            <FsLightbox toggler={lightboxController.toggler} slide={lightboxController.slide} 
                                sources={[                            
                                    gallery1, gallery2,  gallery3, gallery13, gallery14,  gallery15, gallery16, gallery17,  gallery18,  gallery4,  gallery5,  gallery6,  gallery7,  gallery8,  gallery9,  gallery10,  gallery11,  gallery12                      
                                ]}
                            /> 
                            <div class="row gallery-item">
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(1)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery1+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(2)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery2+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(3)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery3+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(4)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery13+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(5)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery14+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(6)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery15+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(7)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery16+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(8)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery17+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(9)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery18+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(10)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery4+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(11)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery5+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(12)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery6+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(13)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery7+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(14)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery8+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(15)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery9+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(16)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery10+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(17)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery11+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(18)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery12+")"}}></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    );
}

export default Gallery;
