import React, { useState,useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import FsLightbox from 'fslightbox-react'; 
import gallery1 from "../img/providenceHeight/DSC_5969.JPG"
import gallery2 from "../img/providenceHeight/DSC_5972.JPG"
import gallery4 from "../img/providenceHeight/DSC_5982.JPG"
import gallery5 from "../img/providenceHeight/DSC_5989.JPG"
import gallery10 from "../img/providenceHeight/DSC_6000.JPG"
import gallery11 from "../img/providenceHeight/DSC_6004.JPG"
import gallery13 from "../img/providenceHeight/DSC_6029.JPG"
import gallery16 from "../img/providenceHeight/DSC_6046.JPG"
import gallery17 from "../img/providenceHeight/DSC_6051.JPG"
import gallery23 from "../img/providenceHeight/DSC_6062.JPG"
import gallery24 from "../img/providenceHeight/DSC_6063.JPG"
import gallery29 from "../img/providenceHeight/DSC_6069.JPG"
// import gallery30 from "../img/providenceHeight/DSC_6078.JPG"
// import gallery31 from "../img/providenceHeight/DSC_6079.JPG"
import gallery200 from "../img/providenceHeight/building1.jpeg"
import gallery190 from "../img/providenceHeight/building2.jpeg"

const ProvidenceHeightsGallery = () => {
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    useEffect(()=>{
        window.scrollTo(0, 0); 
    })
        
    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        } );
    }
    return (
        <>
            <Navbar></Navbar>
            <section class="breadcrumb " style={{ backgroundImage: 'url(../img/gallery/DSC_1462.JPG)', backgroundPosition: 'top', backgroundSize: 'cover', }}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="breadcrumb_iner text-center">
                                <div class="breadcrumb_iner_item">
                                    <h2>Our Gallery</h2>
                                    <p>Home<span>/</span>Gallery</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className=' d-flex'>
                <a href='/providenceHeights/gallery' className='py-4  text-white w-50' style={{backgroundColor: 'rgb(76, 175, 79)'}}>PROVIDENCE HEIGHTS ‘PH’ SECONDARY SCHOOL</a>
                <a href='/gallery'  className='py-4 w-50' style={{backgroundColor: '#f5f5f5',color: 'rgb(76, 175, 79)'}}>PROVIDENCE SCHOOLS</a>
            </div>

            <section class="feature_part single_feature_padding" style={{ marginBottom: '50px' }}>
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-xl-3 align-self-start">
                            <div class="single_feature_text ">
                                <h2>Providence  <br/> Schools Gallery</h2>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xl-9">                  
                            <FsLightbox toggler={lightboxController.toggler} slide={lightboxController.slide} 
                                sources={[                            
                                    gallery1, gallery2, gallery4,  gallery5, gallery10, gallery11,
                                    gallery13, gallery16,  gallery17, gallery23 , gallery24, gallery29, gallery200,gallery190                  
                                ]}
                            /> 
                            <div class="row gallery-item">

                            <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(13)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery200+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(14)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery190+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(1)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery1+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(2)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery2+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(3)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery4+")"}}></div>
                                    </a>
                                </div>
                                
                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(4)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery5+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(5)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery10+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(6)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery11+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(7)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery13+")"}}></div>
                                    </a>
                                </div>

                                

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(8)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery16+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(9)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery17+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(10)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery23+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(11)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery24+")"}}></div>
                                    </a>
                                </div>

                                <div className="col-md-4">
                                    <a onClick={() => openLightboxOnSlide(12)}>
                                        <div className="single-gallery-image" style={{background: "url("+gallery29+")"}}></div>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    );
}

export default ProvidenceHeightsGallery;
