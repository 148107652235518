import React, { useState,useEffect,useRef } from 'react';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import gallery200 from "../img/providenceHeight/building1.jpeg"
import gallery190 from "../img/providenceHeight/building2.jpeg"
import gallery160 from "../img/providenceHeight/DSC_6046.JPG"
import gallery1 from "../img/providenceHeight/DSC_5969.JPG"
import gallery2 from "../img/providenceHeight/DSC_5972.JPG"
import gallery4 from "../img/providenceHeight/DSC_5982.JPG"
import gallery5 from "../img/providenceHeight/DSC_5989.JPG"
import gallery10 from "../img/providenceHeight/DSC_6000.JPG"
import gallery11 from "../img/providenceHeight/DSC_6004.JPG"
import gallery13 from "../img/providenceHeight/DSC_6029.JPG"
import gallery16 from "../img/providenceHeight/DSC_6046.JPG"
import gallery17 from "../img/providenceHeight/DSC_6051.JPG"
import gallery23 from "../img/providenceHeight/DSC_6062.JPG"
import gallery24 from "../img/providenceHeight/DSC_6063.JPG"
import gallery29 from "../img/providenceHeight/DSC_6069.JPG"
// import gallery30 from "../img/providenceHeight/DSC_6078.JPG"
// import gallery31 from "../img/providenceHeight/DSC_6079.JPG"
import Slider from 'infinite-react-carousel';
import Slider2 from 'infinite-react-carousel';
import FsLightbox from 'fslightbox-react'; 
import provHeight from "../img/Logos/Providence_height.png"


const ProvidenceHeights = () => {
    const carousel = useRef(null)

    const settings =  {
        duration:50,
        autoplay: true,
        autoplaySpeed: 2000,
        centerMode: true,
        centerPadding: 10,
        initialSlide: 0,
        
      };

      const settings2 =  {
        arrows: true,
        autoplay: true,
        autoplaySpeed: 500,
        centerMode: true,
        centerPadding: 10,
        initialSlide: 0,
        swipe: false,
        adaptiveHeight: true,
      };

      const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });
        
    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    React.useEffect(()=>{
        window.scrollTo(0, 0); 
    })

    return (
        <>
            <Navbar></Navbar>
            <section className=" pt-5">
                <div className="container-fluid">
                    <div className="row align-items-center pt-5 ">
                        
                        <div className="col-lg-4 col-xl-4 mb-4">
                            <div className="banner_text">
                                <div className="banner_text_iner">
                                <img  src={provHeight} className="img-fluid w-50" alt="" />
                                    {/* <h5 className='text-left'>Providence Schools</h5> */}
                                    <h1 className='text-center mt-2'>PROVIDENCE HEIGHTS ‘PH’ SECONDARY SCHOOL</h1>
                                    <p className='text-center'>Providence Heights ‘Ph’ Secondary School was born out of the conception of the founding fathers to give qualitative education to the less privileged pupils who could not afford the opportunity of attaining sound education in highbrow fee paying schools. The conception was midwifed on Monday 26, September 1994 at Fagba, Iju-Agege, Lagos, South West, Nigeria.</p>
                                    <div className="mt-5 text-center">
                                        <a href="/providenceHeights/about" className="btn_1 mr-5">About Us </a>
                                        <a href="/contact" className="btn_2">Contact Us </a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-8 '>
                            <Slider dots {...settings}>
                                <div>
                                    <img src={gallery200} className="img-fluid d-block w-100" alt="" />
                                </div>
                                <div>
                                    <img src={gallery190} className="img-fluid d-block w-100" alt="" />
                                </div>
                                <div>
                                    <img src={gallery160} className="img-fluid d-block w-100" alt="" />
                                </div>
                                <div>
                                    <img src={gallery1} className="img-fluid d-block w-100" alt="" />
                                </div>
                                <div>
                                    <img src={gallery2} className="img-fluid d-block w-100" alt="" />
                                </div>
                                <div>
                                    <img src={gallery4} className="img-fluid d-block w-100" alt="" />
                                </div>
                                <div>
                                    <img src={gallery5} className="img-fluid d-block w-100" alt="" />
                                </div>
                            </Slider>
                            {/* <Carousel swipeAngle={false} items={1} mouseDrag ref={carousel} autoplay={true} controls={true} nav={true} > 
                                <div>
                                    <img src={gallery1} className="img-fluid d-block" alt="" />
                                </div>
                                <div>
                                    <img src={gallery9} className="img-fluid d-block" alt="" />
                                </div>
                                <div>
                                    <img src={gallery16} className="img-fluid d-block" alt="" />
                                </div>
                                
                            </Carousel> */}
                        </div>
                
                    </div>
                </div>
            </section>


            <section className="feature_part" style={{marginTop:'20px',marginBottom:'100px',}}>
                <div className="container">
                    <div className="row">
                        {/* <div className="col-sm-5 col-lg-4 align-self-center">
                            <div className="single_feature_text ">
                                
                                <img src={gallery1} className="img-fluid d-block" alt="" />
                                <img src={gallery9} className="img-fluid d-block" alt="" />
                                <img src={gallery16} className="img-fluid d-block" alt="" />
                            </div>
                        </div> */}
                        <div className='col-lg-8'>
                            <div className='row'>
                                <div className="col-sm-12">
                                    <div className="single_feature">
                                        <div className="single_feature_part text-left">
                                            <span className="single_feature_icon"><i className="ti-stats-up"></i></span>
                                            <h4>Our Mission</h4>
                                            <ul style={{listStyleType:"circle", paddingLeft:"20px"}}>
                                                <li>
                                                    To mould the students’ character towards the challenges of life.
                                                </li>
                                                <li>
                                                    To inculcate reading habits in the students through regular supply of textbooks and development of a standard library.
                                                </li>
                                                <li>
                                                    To equip the students with communication skills in foreign (English and French) as well as the mother tongues languages.
                                                </li>
                                                <li>
                                                    To impart qualitative education to students in accordance with West African Examination Council (WAEC) and National Examination Council (NECO) syllabi.
                                                </li>
                                                <li>
                                                    To employ qualified and dedicated teachers to handle the students.
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="single_feature">
                                <div className="single_feature_part text-left">
                                    <span className="single_feature_icon"><i className="ti-eye"></i></span>
                                    <h4>Our Vision</h4>
                                    <p>The pursuit of academic excellence,  moral rectitude and physical enrichment.</p>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            </section>

            <section className="learning_part" style={{ backgroundColor:'#f5f5f5' }}>
                <div className="container">
                    <div className="row ">
                        
                        <div className="col-12">
                            <div className="learning_member_text">
                                <h5 className='text-left'>LIST OF SOME AWARDS AND PRIZES WON</h5>
                                <h2 className='text-left'>AWARDS AND PRIZES </h2>
                                <ol>
                                    <li className='text-left mb-4'>
                                        In the 2001, Cowbell National Competition (Mathematics) Adebayo Olubunmi Sekinat came first in Lagos State in the Senior category.
                                    </li>
                                    <li className='text-left mb-4'>
                                        In January 2001, Itata Emmanuel and Olagbaju Titilope came 2nd in the Zonal JETS Competition to qualify for the state level.
                                    </li>
                                    <li className='text-left mb-4'>
                                        In a debate organized by the Ifako Development Association and Iju Youths Association in 2002, the school emerged in 1st position.
                                    </li>
                                    <li className='text-left mb-4'>
                                        Over twelve of our former students enjoyed scholarships overseas: among them are Oyeyemi Oyekanmi, Unites States Presidential Scholarship and Ogunlowo Omolewa who had the highest score in the SAT all over Nigeria in 2002.
                                    </li>
                                    <li className='text-left mb-4'>
                                        In 2003, Koya Theophilus came third in the Lagos Mathematics Olympiad and went on to represent Lagos State at the National level alongside others.
                                    </li>
                                    <li className='text-left mb-4'>
                                        On the 27th May, 2006, Toye Adeola Mariam was honoured by the Lagos State Ministry of Education for representing the state and excelling in the National JETS Competition held in Akure in 2005. She came second at the Federal level.
                                    </li>
                                    <li className='text-left mb-4'>
                                        On 31st October, 2006, the school was honoured with merit awards as Malpractice Free school for the year 2005/2006.
                                    </li>
                                    <li className='text-left mb-4'>
                                        On an NTA Channel 5 Television Debate programme on June 15, 2007 “Co-education is Best for Nigerian Children” the school defeated others to emerge the winner.
                                    </li>
                                    <li className='text-left mb-4'>
                                        On an NTA Channel 5 Television Debate programme on March 12, 2009 on the topic “Parents should be allowed to choose a career for their children” the school defeated others to emerge winner.
                                    </li>
                                    <li className='text-left mb-4'>
                                        On a MITV Television debate Programme on Thursday, June 18, 2009 on the topic: “Petroleum is a curse, rather than a blessing in Nigeria” the school defeated others to emerge winner.
                                    </li>
                                    <li className='text-left mb-4'>
                                        The school came 1st in WAEC’s 60th Anniversary quiz competition organized by WAEC on Friday March 16, 2012.
                                    </li>
                                    <li className='text-left mb-4'>
                                        The school’s representatives placed 1st and 3rd respectively in the senior and junior categories of the 2013 Mathematics Olympiad organized by the Mathematics Association of Nigeria.
                                    </li>
                                    
                                </ol>
                                <div className="mt-5 text-center">
                                    <a href="/providenceHeights/about" className="btn_1 mr-5">Read more </a>
                                    {/* <a href="/contact" className="btn_2">Contact Us </a> */}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
            
            
            
            <Footer></Footer>

        </>
    );
}

export default ProvidenceHeights;
