import React,{useState,useEffect} from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import emailjs from 'emailjs-com';

const Contact = () => {
    useEffect(()=>{
        window.scrollTo(0, 0); 
        
    },[])
    const [form, setForm ] = useState({
        message: '',
        name:'',
        email:'',
        subject:'',
        loading:false
    });
    const [errors,setErrors] = useState({})
    const [success,setSuccess] = useState("")

    const submit = (e) => {
        e.preventDefault()
        const theForm = new FormData();
        theForm.append('message', form.message);
        theForm.append('name', form.name);
        theForm.append('email', form.email);
        theForm.append('subject', form.subject);
        setForm({...form,loading:true})

        emailjs.sendForm(`service_2yymw4o`, "template_2ucczfw", e.target, "user_Vk6Q55F0Op0DwCwDI2Jrw")
        .then((result) => {
            alert("Message Sent, We will get back to you shortly", result.text);
        },
        (error) => {
            console.log(error)
            alert("An error occurred, Please try again", error.text);
        });

        // axios.get('https://providenceapi.recaltd.com/sanctum/csrf-cookie')
        // .then(response => {
        //     axios.post('https://providenceapi.recaltd.com/contact',theForm)
        //     .then(res => {
        //         setForm({
        //             title: '',
        //             description:'',
        //             image:'',
        //             loading:false
        //         })
        //         alert(res.data.message)
        //         setSuccess(res.data.message)
                
        //     }).catch(error => {
        //         setErrors(error.response.data.errors)
        //         setForm({...form,loading:false})
        //     })
        // }).catch(error => {
        //     console.log(error)
        //     setForm({...form,loading:false})
        // });
        
    }

    return (
        <>
            <Navbar></Navbar>
           <section class="breadcrumb " style={{backgroundImage: 'url(img/gallery/DSC_1462.JPG)', backgroundPosition: 'top', backgroundSize: 'cover',}}>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="breadcrumb_iner text-center">
                                <div class="breadcrumb_iner_item">
                                    <h2>Contact us</h2>
                                    <p>Home<span>/</span>Contact us</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="contact-section section_padding">
                <div class="container">
                    <div class="row">
                        
                        <div class="col-lg-6">
                            <h3 className="text-left mb-5">
                                Providence Primary
                            </h3>
                            <div class="media contact-info">
                                <span class="contact-info__icon"><i class="ti-home"></i></span>
                                <div class="media-body text-left">
                                    <h3> 165 Iju road, Fagba Bus Stop, Agege, Lagos State.</h3>
                                    {/* <p>Lagos State, Nigeria </p> */}
                                </div>
                            </div>
                            <div class="media contact-info">
                                <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                                <div class="media-body text-left">
                                    <h3 className="text-left" style={{display:"flex", flexDirection:"column"}}>
                                        <a style={{color:'#2a2a2a'}} href="tel:08039698429">08039698429</a>
                                        <a style={{color:'#2a2a2a'}} href="tel:08023671325">08023671325</a>
                                        <a style={{color:'#2a2a2a'}} href="tel:08093349900">08093349900</a>
                                        <a style={{color:'#2a2a2a'}} href="tel:08122343404">08122343404</a>
                                    </h3>
                                    <p>Mon to Fri 8am to 4pm</p>
                                </div>
                            </div>
                            <div class="media contact-info">
                                <span class="contact-info__icon"><i class="ti-email"></i></span>
                                <div class="media-body text-left">
                                    <h3> <a style={{color:'#2a2a2a'}} href="mailto:info@providenceschoolsng.com">info@providenceschoolsng.com</a> </h3>
                                    <p>Send us your Enquiry anytime!</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                           
                            <h3 className="text-left mb-5">
                                Providence Heights
                            </h3>
                            <div class="media contact-info">
                                <span class="contact-info__icon"><i class="ti-home"></i></span>
                                <div class="media-body text-left">
                                    <h3>Providence Avenue, Fagba Bus stop, Agege, Lagos State Nigeria.</h3>
                                    {/* <p>Lagos State, Nigeria </p> */}
                                </div>
                            </div>
                            <div class="media contact-info">
                                <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                                <div class="media-body text-left">
                                    <h3 className="text-left" style={{display:"flex", flexDirection:"column"}}>
                                        <a style={{color:'#2a2a2a'}} href="tel:07086505452">Principal: 07086505452</a>
                                        <a style={{color:'#2a2a2a'}} href="tel:07069340745">VP: 07069340745</a>
                                        
                                    </h3>
                                    <p>Mon to Fri 8am to 4pm</p>
                                </div>
                            </div>
                            <div class="media contact-info">
                                <span class="contact-info__icon"><i class="ti-email"></i></span>
                                <div class="media-body text-left">
                                    <h3> <a style={{color:'#2a2a2a'}} href="mailto:info@providenceschoolsng.com">info@providenceschoolsng.com</a> </h3>
                                    <p>Send us your Enquiry anytime!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-12 mt-5 mb-5">
                            <h2 class="contact-title text-left">Get in Touch</h2>
                        </div>
                        <div class="col-lg-7">
                            <form onSubmit={(e)=>submit(e)} class="form-contact contact_form"method="post">
                                <div class="row">
                                {
                                        success?(
                                            <div className="alert alert-success w-100">
                                                <h4>{success}</h4>
                                            </div>
                                        ):(<></>)
                                    }
                                    
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input value={form.name} onChange={(e)=> setForm({...form,name: e.target.value })} class="form-control" name="name" id="name" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'" placeholder='Enter your name' required/>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <input value={form.email} onChange={(e)=> setForm({...form,email: e.target.value })} class="form-control" name="email" id="email" type="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" placeholder='Enter email address' required/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input value={form.subject} onChange={(e)=> setForm({...form,subject: e.target.value })} class="form-control" name="subject" id="subject" type="text" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Subject'" placeholder='Enter Subject' required/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <textarea value={form.message} onChange={(e)=> setForm({...form,message: e.target.value })} class="form-control w-100" name="message" id="message" cols="30" rows="9" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Message'" placeholder='Enter Message' required></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mt-3">
                                {
                                        form.loading? (
                                            <button   className="button button-contactForm btn_1 px-5">Submitting....</button>
                                        ):(
                                            <button  type="submit" className="button button-contactForm btn_1 px-5">Submitt</button>
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    );
}

export default Contact;
