import React from 'react';
import niitLogo from "../img/Logos/NIIT.png"


const Footer = () => {
    return (
        <footer className="footer-area">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-sm-6 col-md-4 col-xl-4">
                        <div className="single-footer-widget footer_1">
                            <h4>Quick Links</h4>
                            <ul>
                                <li>
                                    <a href="/" className="text-left">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a href="/about" className="text-left">
                                        About
                                    </a>
                                </li>
                                <li>
                                    <a href="/gallery" className="text-left">
                                        Gallery
                                    </a>
                                </li>
                                <li>
                                    <a href="/contact" className="text-left">
                                        Contact
                                    </a>
                                </li>
                            </ul>
                            {/* <p className="text-black">Providence Schools was established in 1984. The mission of the school is to raise a generation of young people who will break barriers, challenge the status quo in the quest to change their world.</p> */}
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-xl-4">
                        <div className="single-footer-widget footer_2">
                            <h4>Our Social Links</h4>
                            <p>Stay updated with our latest News
                            </p>
                            <div className="social_icon">
                                <a target="_blank" href="https://www.facebook.com/providenceschoolsofficial"> <i className="ti-facebook"></i> </a>
                                <a target="_blank" href="https://www.twitter.com/providenceschs"> <i className="ti-twitter-alt"></i> </a>
                                <a target="_blank" href="https://www.instagram.com/providenceschoolsofficial/"> <i className="ti-instagram"></i> </a>
                                <a target="_blank" href="https://www.youtube.com/channel/UCcx5L6J9XVC7bKcouJFw2Zg"> <i className="ti-youtube"></i> </a>
                            </div>
                            <p className="text-center mt-4">
                                <img width={150} className="img-fluid" src={niitLogo}></img>

                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6 col-md-4">
                        <div className="single-footer-widget footer_2">
                            <h4>Contact us</h4>
                            <div className="contact_info">
                                {/* <p><span> Providence Heights :</span>  Providence Avenue, Fagba Bustop, Agege, Lagos State, Nigeria </p>
                                <p><span> Providence Primary :</span>  Providence Avenue, Fagba Bustop, Agege, Lagos State, Nigeria </p> */}
                                <p style={{display:"flex", flexDirection:"column"}}>
                                    <span> Providence Heights :</span> 
                                    <a style={{color:'#fff'}} href="tel:07086505452">07086505452</a>
                                    <a style={{color:'#fff'}} href="tel:07069340745">07069340745</a>
                                    <p>Providence Avenue, Fagba Bus stop, Agege, Lagos State Nigeria.</p>
                                </p>
                                <p style={{display:"flex", flexDirection:"column"}}>
                                    <span> Providence Primary :</span> 
                                    <a style={{color:'#fff'}} href="tel:08039698429">08039698429</a>
                                    <a style={{color:'#fff'}} href="tel:08023671325">08023671325</a>
                                    <a style={{color:'#fff'}} href="tel:08093349900">08093349900</a>
                                    <a style={{color:'#fff'}} href="tel:08122343404">08122343404</a>
                                    <p>165 Iju road, Fagba Bus Stop, Agege, Lagos State.</p>
                                </p>
                                <p style={{display:"flex", flexDirection:"column"}}><span> Email : </span> <a style={{color:'white'}} href="mailto:info@providenceschoolsng.com">info@providenceschoolsng.com</a> </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="copyright_part_text text-center">
                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="footer-text m-0"> Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved  </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
