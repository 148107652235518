import React, {useContext, useState} from 'react';
import firebase from "firebase/app";
import '@firebase/auth'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';
import {AuthContext} from "../App"
import { useHistory } from 'react-router-dom'; 

// import app from "../base"

const Login = () => {
    const [form, setForm] = useState({
        email:'',
        password:'',
        loading:false
    });
    const useTest =  useContext(AuthContext);
    let history = useHistory();
    const [errors,setErrors] = useState({})

    const dologin = ()=>{
        setForm({...form,loading:true})
        console.log(form)

        // try{
        //     firebase.app.auth().signInWithEmailAndPassword(form.email, form.password)
        // }catch(error){
        //     alert(error)
        // }
        

        axios.defaults.withCredentials = true;
        // axios.defaults.headers["Access-Control-Allow-Origin"] = true;
            
        axios.get('/sanctum/csrf-cookie')
        .then(response => {
            axios.get('/login?email='+form.email+'&password='+form.password)
                .then(res => {
                    useTest.login(history,res.data)
                    
                }).catch(error => {
                    setErrors(error.response.data.errors)
                    setForm({...form,loading:false})
                    console.log(error)
                })
        }).catch(error => {
            console.log(error)
            setForm({...form,loading:false})
        });
    }
    
    return (
        <>
            <Navbar></Navbar>
            <link rel="stylesheet" href="css/login.css"></link>
            <div className="wrapper fadeInDown">
                <div id="formContent">

                    <div className="fadeIn first">
                        <h1 className="mt-5">Login</h1>
                    </div>

                    {/* <form> */}
                        {
                            errors.email? (
                                <div className=" ">
                                    <p className="text-center text-danger"> {errors.email[0]} </p> 
                                </div>
                            ):(<></>)
                        }
                        <input value={form.email} type="text" onChange={(e)=> setForm({...form,email:e.target.value})} id="login" className="fadeIn second" name="login" placeholder="login"/>
                        {
                            errors.password? (
                                <div className=" ">
                                    <p className="text-center text-danger"> {errors.password[0]} </p> 
                                </div>
                            ):(<></>)
                        }
                        <input value={form.password} type="password" onChange={(e)=> setForm({...form,password:e.target.value})} id="password" className="fadeIn third" name="login" placeholder="password"/>
                        {/* <input  value=""/> */}
                        {/* <button  type="submit" onClick={()=> useTest.login(history)} className="fadeIn fourth">Log In</button> */}
                        {
                            form.loading? (
                                <button  type="submit" className="fadeIn fourth">Loading</button>

                            ):(
                                <button  type="submit" onClick={()=> dologin()} className="fadeIn fourth">Log In</button>
                            )
                        }
                    {/* </form> */}
                </div>
            </div>
            <Footer></Footer>
        </>
    );
}

export default Login;
