import React, { useState,useEffect,useRef } from 'react';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import gallery200 from "../img/providenceHeight/building1.jpeg"
import gallery190 from "../img/providenceHeight/building2.jpeg"
import gallery160 from "../img/providenceHeight/DSC_6046.JPG"
import gallery1 from "../img/providenceHeight/DSC_5969.JPG"
import gallery2 from "../img/providenceHeight/DSC_5972.JPG"
import gallery4 from "../img/providenceHeight/DSC_5982.JPG"
import gallery5 from "../img/providenceHeight/DSC_5989.JPG"
import gallery6 from "../img/gallery/DSC_6101.JPG"
import gallery7 from "../img/gallery/DSC_6103.JPG"
import gallery8 from "../img/gallery/DSC_6106.JPG"
import gallery9 from "../img/gallery/52.jpg"
import gallery11 from '../img/gallery/DSC_1453.JPG'
import gallery12 from "../img/gallery/DSC_1463.JPG"
import gallery13 from "../img/gallery/DSC_1456.JPG"
import provSchools from "../img/Logos/Providence_Schools.png"
import provHeight from "../img/Logos/Providence_height.png"
// import Slider from 'infinite-react-carousel';
import Slider from 'react-slick';
import Slider2 from 'react-slick';
import FsLightbox from 'fslightbox-react'; 
import axios from 'axios';




const Home = () => {

    const [post, setPost] = useState({}); 
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        window.scrollTo(0, 0);
        // axios.get('https://providence-backend.lightningspeedltd.com/sanctum/csrf-cookie')
        // .then(response => {
        //     axios.get('https://providence-backend.lightningspeedltd.com/posts')
        //     .then(res => {
        //         console.log(res.data)
        //         setPost(res.data)
        //         setLoading(false)

        //         // console.log(JSON.stringify(post))
        //     }).catch(error => {
        //         setLoading(true)
        //     })
        // }).catch(error => {
        //     console.log(error)
        //     setLoading(true)
        // });
        axios.get('/api/posts')
            .then(res => {
                console.log(res.data)
                setPost(res.data)
                setLoading(false)

                console.log(JSON.stringify(post))
            }).catch(error => {
                setLoading(true)
            })
    },[])

    const carousel = useRef(null)

    const settings =  {
        duration:50,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        infinite: true,
        initialSlide: 0,
        arrows: true
      };

      const settings2 =  {
        arrows: true,
        autoplay: true,
        autoplaySpeed: 500,
        centerMode: true,
        centerPadding: 10,
        initialSlide: 0,
        swipe: false,

      };

      const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });
        
    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    return (
        <>
            <Navbar></Navbar>
            <section className=" pt-5">
                <div className="container-fluid" style={{marginTop:'40px'}}>
                    
                    <Slider dots {...settings}>
                        <div>
                            <img style={{ height: "80vh", objectFit:"cover" }} src={gallery200} className="img-fluid d-block w-100" alt="" />
                        </div>
                        <div>
                            <img style={{ height: "80vh", objectFit:"cover" }} src={gallery190} className="img-fluid d-block w-100" alt="" />
                        </div>
                        <div>
                            <img style={{ height: "80vh", objectFit:"cover" }} src={gallery160} className="img-fluid d-block w-100" alt="" />
                        </div>
                        <div>
                            <img style={{ height: "80vh", objectFit:"cover" }} src={gallery11} className="img-fluid d-block w-100" alt="" />
                        </div>
                        <div>
                            <img style={{ height: "80vh", objectFit:"cover" }} src={gallery12} className="img-fluid d-block w-100" alt="" />
                        </div>
                        <div>
                            <img style={{ height: "80vh", objectFit:"cover" }} src={gallery4} className="img-fluid d-block w-100" alt="" />
                        </div>
                        <div>
                            <img style={{ height: "80vh", objectFit:"cover" }} src={gallery5} className="img-fluid d-block w-100" alt="" />
                        </div>

                        <div>
                            <img style={{ height: "80vh", objectFit:"cover" }} src={gallery6} className="img-fluid d-block w-100" alt="" />
                        </div>
                        <div>
                            <img style={{ height: "80vh", objectFit:"cover" }} src={gallery7} className="img-fluid d-block w-100" alt="" />
                        </div>
                        <div>
                            <img style={{ height: "80vh", objectFit:"cover" }} src={gallery8} className="img-fluid d-block w-100" alt="" />
                        </div>
                        <div>
                            <img style={{ height: "80vh", objectFit:"cover" }} src={gallery9} className="img-fluid d-block w-100" alt="" />
                        </div>
                    </Slider>
                    {/* <div className="row">
                        <div className="col-lg-6 text-center my-5">
                            <a href="/providencePrimary">
                                <img className="img-fluid" src={provSchools}></img>
                            </a>
                        </div>
                        <div className="col-lg-6 text-center  my-5">
                            <a href="/providenceSecondary">

                                <img className="img-fluid" src={provHeight}></img>

                            </a>
                        </div>
                    </div> */}
                </div>
            </section>


            <section className="learning_part">
                <div className="container">
                    <div className="row ">
                        
                        <div className="col-12">
                            <div className="learning_member_text">
                                <h5 className='text-justify'>Management</h5>
                                <h2 className='text-justify'>Welcome Address </h2>
                                <p className='text-justify'>  <b>Providence Schools </b> are co-education schools in Ifako-Ijaiye, Lagos, Nigeria. It provides Nursery, Primary and Secondary education system. A caring, striving community of learners who have made their decision to study for specific reasons; good teachers, affordable tuition and stimulating environment. We aspire for our students to know that they are distinct and need to dedicate their lives to the Almighty God who gives wisdom and excellence. </p>
                                <p className='text-justify'> The pupils’ uniqueness and talents are also taken care of. These and more are the pillars upon which <b> PROVIDENCE SCHOOLS’ SUCCESS </b> has been built. </p>
                                <p className='text-justify'>Across the curriculum, the school guides students through idea-driven exploration and problem solving.</p>

                                <div className="mt-5 text-center">
                                    <a href="/providenceHeights/about" className="btn_1 mr-5">Read more </a>
                                    {/* <a href="/contact" className="btn_2">Contact Us </a> */}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

            <section className="blog_part section_padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-5">
                            <div className="section_tittle text-center">
                                <p style={{ fontSize:"18px", textTransform:"uppercase"}}>Our Blog</p>
                                <h2>Students Blog</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    {
                        loading == false && post.length > 0 ?
                        (
                            post.map((item,index)=>{
                                return (
                                    <Post item={item} />
                                )
                            })
                            
                        ):
                        (
                            <h3 className="text-center mx-auto text-black-50">No Post</h3>
                        )
                    }
                    </div>
                </div>
            </section>
            
            <section className="testimonial_part section_padding">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-xl-5">
                            <div className="section_tittle text-center">
                                <p>tesimonials</p>
                                <h2>Happy Students</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                        <Slider2 dots > 
                            <div className="testimonial_slider ">
                                <div className="row">
                                    <div className=" offset-lg-1 col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                        <div className="testimonial_slider_text">
                                            <p>At Providence schools I have become exactly the person I wanted to be.</p>
                                            <h4>-- Engr. Owotomi Gbolagade</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                        <div className="testimonial_slider_text">
                                            <p>Thank you for fostering the kind of learning community that most only dream of </p>
                                            <h4>-- Adetutu Savage 2001</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial_slider">
                                <div className="row">
                                    <div className=" offset-lg-1 col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                        <div className="testimonial_slider_text">
                                            <p>Providence Schools really prepared me. A lot of my classes at Providence had some of the same content as I’m learning now in college.</p>
                                            <h4>-- Nwachukwu Uche 2013</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                        <div className="testimonial_slider_text">
                                            <p>The Providence Schools gave me the fullest enthusiasm for life learning and it got me into the college of my dreams.</p>
                                            <h4>-- Barr. O. Osiyale Osifalujo</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial_slider">
                                <div className="row">
                                    <div className=" offset-lg-1 col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                        <div className="testimonial_slider_text">
                                            <p>The Providence schools has an amazing ability to attract top people who work for the love of teaching. The school has given me a tremendous amount of freedom to pursue knowledge and experiences far beyond that of a typical high school, and has challenged me to explore my interests in great depth.</p>
                                            <h4>-- Dr Okifun Gregory</h4>
                                        </div>
                                    </div>
                                    <div className="  col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                        <div className="testimonial_slider_text">
                                            <p>I gained self-responsibility and personal empowerment… I loved attending Providence Schools and miss it immensely.</p>
                                            <h4>-- Nita E. Nunoo 2019</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial_slider">
                                <div className="row">
                                    <div className=" offset-lg-1 col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                        <div className="testimonial_slider_text">
                                            <p>Not only does the school prepare us for college and the outside world, but also how to deal with life as a whole.</p>
                                            <h4>-- Dr Ogundare Adewale, Alumni, class of ’95</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                        <div className="testimonial_slider_text">
                                            <p>I cannot imagine feeling any more satisfied with my life than I do right now, but if I am able to inspire students the way you have inspired me, my guess is that I will.</p>
                                            <h4>-- Dr Adekoya Adeyosoye</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial_slider">
                                <div className="row">
                                    <div className=" offset-lg-1 col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                        <div className="testimonial_slider_text">
                                            <p>I can’t believe I can like a school so much.</p>
                                            <h4>-- Engr. Dairo Olaoluwa</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                        <div className="testimonial_slider_text">
                                            <p>The creativity and resourcefulness I had was unlocked at Providence schools. I love the connections with teachers.</p>
                                            <h4>-- Iria Ogbeide Joseph 2002</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="testimonial_slider">
                                <div className="row">
                                    <div className=" offset-lg-1 col-lg-5 col-xl-5 col-sm-8 align-self-center" style={{marginBottom: '40px'}}>
                                        <div className="testimonial_slider_text">
                                            <p>I love this school. It feels like home</p>
                                            <h4>-- Shobande Treasure</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider2>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="member_counter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 offset-lg-3">
                            <div className="single_member_counter">
                                <span className="counter">102</span>
                                <h4>Number of Staffs</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="single_member_counter">
                                <span className="counter">436</span>
                                <h4>Number of Students</h4>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section> */}
            
            <section>
                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe style={{ width:"97vw", height:"500px" ,marginLeft: "auto" ,marginRight: "auto" }} id="gmap_canvas" src="https://maps.google.com/maps?q=Providence%20Avenue,%20Fagba%20Bustop,%20Agege,%20Lagos%20State,%20Nigeria&t=&z=15&ie=UTF8&iwloc=&output=embed" >
                        </iframe>
                    </div>
                </div>
            </section>
            
            <Footer></Footer>

        </>
    );
}

export default Home;


function Post(props){
    const [showAll, setShowAll] = useState(false)
    
     
    return (

        <div className="col-sm-6 col-lg-4 col-xl-4 " style={{height:"100%"}} key={props.item.id}>
            <div className="single-home-blog">
                <div className="card">
                    <img style={{ height: "300px", objectFit:"cover" }} src={props.item.image} className="card-img-top" alt="blog"/>
                    <div className="card-body">
                        <a href="">
                            <h5 className="card-title">{props.item.title}</h5>
                        </a>
                        <p>  { !showAll ? props.item.description.slice(0, 200): props.item.description} </p>

                        {
                            props.item.description.length > 200 && (
                                <>
                                    {showAll ? ( 
                                        <p style={{ marginTop: "15px",}}> <a onClick={(e) => { e.preventDefault(); setShowAll(false)}} style={{color: "#ff663b"}} href="#">View Less</a> </p>
                                    ) :(
                                        <p style={{ marginTop: "15px",}}> <a onClick={(e) => { e.preventDefault(); setShowAll(true)}} style={{color: "#ff663b"}} href="#">Read More</a> </p>
                                    )}
                                </>
                            )
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}